import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { pb } from '@/pocketbase'

import SignInView from '@/views/SignInView.vue'
import HomeView from '@/views/HomeView.vue'
import VerifyEmailView from "@/views/VerifyEmailView.vue";
import TechLandscapeView from "@/views/TechLandscape/TechLandscapeView.vue";
import InitiativesView from "@/views/InitiativesView.vue";
import ResourcesView from "@/views/ResourcesView.vue";
import RuleOf40View from "@/views/Resources/RuleOf40View.vue";
import RecommendationsView from "@/views/RecommendationsView.vue";
import InitiativeView from "@/views/InitiativeView.vue";
import TechnologyView from "@/views/TechnologyView.vue";
import TechnologyDay2024View from "@/views/Events/TechnologyDay2024View.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/tech-landscape',
    children: [
      {
        path: '',
        name: 'tech_landscape',
        component: TechLandscapeView,
      },
      {
        path: 'technology/:id',
        name: 'technology',
        component: TechnologyView,
      },
    ]
  },
  {
    path: '/initiatives',
    children: [
      {
        path: '',
        name: 'initiatives',
        component: InitiativesView,
      },
      {
        path: ':slug',
        name: 'initiative',
        component: InitiativeView,
      }
    ]
  },
  {
    path: '/recommendations',
    name: 'recommendations',
    component: RecommendationsView,
  },
  {
    path: '/resources',
    children: [
      {
        path: '',
        name: 'resources',
        component: ResourcesView,
      },
      {
        path: 'ruleof40',
        name: 'ruleof40',
        component: RuleOf40View,
      }
    ]
  },
  {
    path: '/events',
    children: [
      {
        path: '0659e973-9268-7793-8000-beb625690805/technology-day-2024',
        redirect: {
          name: 'technology-day-2024',
        },
      },
      {
        path: '0659e973/technology-day-2024',
        name: 'technology-day-2024',
        component: TechnologyDay2024View,
      }
    ],
    meta: {
      allow_guest: true,
    },
  },
  {
    path: '/auth/login',
    name: 'login',
    component: SignInView,
    meta: {
      allow_guest: true,
    },
  },
  {
    path: '/auth/verify-email',
    name: 'verify_email',
    component: VerifyEmailView,
    meta: {
      allow_guest: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  // already logged in
  if (to.name === 'login' && pb.authStore.isValid) {
    return { name: 'home' };
  }

  // already verified
  if (to.name === 'verify_email' && pb.authStore.isValid && pb.authStore.model.verified) {
    return { name: 'home' };
  }

  // login needed
  if (!to.meta.allow_guest && !pb.authStore.isValid) {
    return { name: 'login' };
  }

  // email verification needed
  if (!to.meta.allow_guest && pb.authStore.isValid && !pb.authStore.model.verified) {
    return { name: 'verify_email' };
  }

  return true;
})

export default router
