export interface Initiative {
  id: string
  slug: string
  startDate: string
  endDate: string | null
  name: string
  abstract: string
  details: string
  files: string[]
}

export interface ProductTechnology {
  id: string
  product: string
  technology: string
  version: string
  comment: string
  expand?: {
    product?: Product
    technology?: Technology
  }
}

export enum TechnologyCategory {
  application = 'Application',
  data = 'Data and Storage',
  infrastructure = 'Servers and Infrastructure',
  devops = 'DevOps',
  service = 'Business Services and Tools',
}

export enum TechnologyType {
  // Application
  programming_language = 'Programming Languages',
  frontend_framework = 'Frontend Frameworks and Libraries',
  backend_framework = 'Backend Frameworks and Libraries',
  mobile_framework = 'Mobile Frameworks and Libraries',
  protocol = 'Standards and Protocols',
  framework = 'Frameworks', // deprecated
  library = 'Libraries', // deprecated

  // data
  database = 'Databases',
  message_queue = 'Message Queues and Events Streaming',
  version_control = 'Version Control',

  // infrastructure
  os = 'Operating System',
  web_server = 'Web Servers',
  cloud_platform = 'Cloud Platforms',
  infrastructure_provider = 'Other Infrastructure Providers',
  server = 'Servers and Infrastructure', // deprecated

  // devops
  container_orchestration = 'Containerization and Orchestration',
  ci_cd = 'CI/CD and Build Tools',
  monitoring = 'Monitoring and Logging',
  configuration_management = 'Configuration Management',
  auth = 'Authentication and Authorization',
  devops_tool = 'Other DevOps Tools',

  // tools
  bi = 'Business Intelligence',
  project_management = 'Project and Product Management',
  communication = 'Communication Tools',
  saas_tool = 'Other SaaS Tools',
  provider = 'Service Providers', // deprecated
  tool = 'Tools', // deprecated
}

export const technologyTypesByCategory: { category: string, types: string[] }[] = [{
  category: TechnologyCategory.application,
  types: [
    TechnologyType.programming_language,
    TechnologyType.backend_framework,
    TechnologyType.frontend_framework,
    TechnologyType.mobile_framework,
    TechnologyType.framework,
    TechnologyType.library,
    TechnologyType.protocol,
  ]
}, {
  category: TechnologyCategory.data,
  types: [
    TechnologyType.database,
    TechnologyType.message_queue,
    TechnologyType.version_control,
  ]
}, {
  category: TechnologyCategory.infrastructure,
  types: [
    TechnologyType.os,
    TechnologyType.web_server,
    TechnologyType.cloud_platform,
    TechnologyType.infrastructure_provider,
    TechnologyType.server,
  ]
}, {
  category: TechnologyCategory.devops,
  types: [
    TechnologyType.container_orchestration,
    TechnologyType.ci_cd,
    TechnologyType.configuration_management,
    TechnologyType.monitoring,
    TechnologyType.auth,
    TechnologyType.devops_tool,
  ]
}, {
  category: TechnologyCategory.service,
  types: [
    TechnologyType.project_management,
    TechnologyType.communication,
    TechnologyType.bi,
    TechnologyType.saas_tool,
    TechnologyType.provider,
    TechnologyType.tool,
  ]
}];

export interface Technology {
  id: string
  name: string
  type: TechnologyType
  description: string
  website: string
  logo: string
  simpleicon: string
}

export interface Product {
  id: string
  name: string
  businessUnit: string
  contacts: string[]
  expand?: {
    businessUnit?: BusinessUnit
    contacts?: ProductContact[]
  }
}

export enum ContactRole {
  product_lead = 'Product Lead',
  product_manager = 'Product Manager',
  tech_lead = 'Technical Lead',
  dev_lead = 'Lead Developer',
  developer = 'Software Developer',
  architect = 'Software Architect',
  sysadmin = 'System Admin / DevOps Engineer',
}

export interface ProductContact {
  id: string
  name: string
  email: string
  roles: ContactRole[],
}

export interface BusinessUnit {
  id: string
  name: string
}

export enum RecommendationActivity {
  watching = 'Things to watch',
  reading = 'Things to read',
  listening = 'Things to listen to'
}

export enum RecommendationTopic {
  entrepreneurship = 'Entrepreneurship',
  project_management = 'Project Management',
  product_management = 'Product Management',
  software_development = 'Software Development',
  software_architecture = 'Software Architecture',
  health = 'Health',
  other = 'Other',
}

export enum Language {
  en = 'English',
  de = 'German',
}

export interface Recommendation {
  id: string
  title: string
  description: string
  activity: RecommendationActivity
  topics: RecommendationTopic[]
  language: Language
  url: string
  image: string
  created: string
}
